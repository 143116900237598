import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Campaign, SerialNumStatus } from '@web-entry/models';
import { first, firstValueFrom } from 'rxjs';
import { DataService } from '../service/data.service';
import { StateService } from '../service/state.service';

@Injectable({
  providedIn: 'root',
})
export class InitDataResolver implements Resolve<Promise<void>> {
  campaign: Campaign | null = null;

  constructor(
    private stateService: StateService,
    private dataService: DataService,
    private router: Router
  ) {}

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<void> {
    const serialNum = route.paramMap.get('id');
    console.log(serialNum);
    // シリアル番号を取得できなかった場合は404ページへ
    if (!serialNum) {
      this.stateService.isInitialized.next(true);
      return;
    }
    // 初期化完了フラグObservable
    this.stateService.isInitialized$.pipe(first()).forEach(async (v) => {
      console.log(v);
      // 初期化処理
      if (!v) {
        // キャンペーン情報取得・キャンペーン情報Observableセット
        await this.dataService.initCampaignData();
        this.campaign = await firstValueFrom(this.dataService.campaign$);
        // シリアル番号の状態Observableセット
        await this.stateService.setSerialNumState(serialNum, this.campaign);
        // キャンペーン開催状況Observableセット
        this.stateService.setHoldingState(this.campaign);
        this.routerGuard(state);
        this.stateService.setIsInitialized(true);
      } else {
        this.stateService.setHoldingState(this.campaign);
        this.routerGuard(state);
      }
    });
  }

  // 画面遷移の制御
  async routerGuard(state: RouterStateSnapshot) {
    window.scrollTo({ top: 0 });
    // キャンペーン情報の取得ができない、または開催前の場合は404ページへ
    if (!this.campaign) {
      this.router.navigate(['/']);
      return;
    }
    // シリアル番号の状態確認
    const serialNumState = await firstValueFrom(
      this.stateService.serialNumState$
    );

    // 無効なURLでアクセスしていた場合、404ページへ
    if (serialNumState.status == SerialNumStatus.invalid) {
      console.log(state.url);
      if (state.url !== `/${serialNumState.serialNum}/notfound`) {
        console.log(state.url);
        this.router.navigate([`/${serialNumState.serialNum}/notfound`]);
      }
      return;
    }
    // 応募済みのシリアル番号だった場合、応募完了ページへ
    if (serialNumState.status == SerialNumStatus.used) {
      if (state.url !== `/${serialNumState.serialNum}/thanks`) {
        console.log(state.url);
        this.router.navigate([`/${serialNumState.serialNum}/thanks`]);
      }
      return;
    }

    // 開催状況確認
    const holdingState = await firstValueFrom(this.stateService.holdingState$);
    // キャンペーンが終了していた場合
    if (!holdingState) {
      this.router.navigate([`/${serialNumState.serialNum}`]);
      return;
    }
  }
}
