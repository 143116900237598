import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { InitDataResolver } from './core/resolver/init-data.resolver';
import { NotFoundComponent } from './modules/not-found/not-found.component';

const routes: Routes = [
  {
    path: ':id',
    loadChildren: () =>
      import('./modules/top/top.module').then((m) => m.TopModule),
    resolve: [InitDataResolver],
  },
  {
    path: ':id/confirm',
    loadChildren: () =>
      import('./modules/confirm/confirm.module').then((m) => m.ConfirmModule),
    resolve: [InitDataResolver],
  },

  {
    path: ':id/thanks',
    loadChildren: () =>
      import('./modules/thanks/thanks.module').then((m) => m.ThanksModule),
    resolve: [InitDataResolver],
  },
  {
    path: '**',
    component: NotFoundComponent,
    resolve: [InitDataResolver],
  },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
