import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { StateService } from './core/service/state.service';

@Component({
  selector: 'web-entry-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'sasebo5bangai';
  loadingState!: Observable<boolean>;
  isInitialized!: Observable<boolean>;
  constructor(private stateService: StateService) {}

  ngOnInit() {
    this.loadingState = this.stateService.loadingState$;
    this.isInitialized = this.stateService.isInitialized$;
  }
}
