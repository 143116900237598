export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyB0ioyYMR6m6pqyF7tq6AxU45Lu9d_prQY',
    authDomain: 'sasebo5bangai-dev.firebaseapp.com',
    projectId: 'sasebo5bangai-dev',
    storageBucket: 'sasebo5bangai-dev.appspot.com',
    messagingSenderId: '941373193211',
    appId: '1:941373193211:web:64a97cbc2f040dc304a95e',
    measurementId: 'G-NBVDS7LFHJ',
  },
  campaign_id: 'gw2024',
};
