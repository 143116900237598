import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import * as dayjs from 'dayjs';
import { Campaign, SerialNumStatus } from '@web-entry/models';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { environment } from '../../../../src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  // 初期化フラグ
  isInitialized = new BehaviorSubject<boolean>(false);
  // 開催状況
  holdingState = new BehaviorSubject<boolean>(false);
  // シリアル番号状態
  serialNumState = new BehaviorSubject<{
    serialNum: string;
    status: SerialNumStatus;
  }>({ serialNum: '', status: SerialNumStatus.invalid });

  // ローディング状態
  loadingState = new BehaviorSubject<boolean>(false);

  serialNumber: string | null = null;

  constructor(private functions: AngularFireFunctions) {}

  get isInitialized$(): Observable<boolean> {
    return this.isInitialized.asObservable();
  }

  setIsInitialized(isInitialized: boolean) {
    this.isInitialized.next(isInitialized);
  }

  get loadingState$(): Observable<boolean> {
    return this.loadingState.asObservable();
  }

  setLoadingState(isLoading: boolean) {
    this.loadingState.next(isLoading);
  }

  // キャンペーン開催状況をセットする。終了日時より前の場合はtrue、終了日時を過ぎている場合はfalseを流す
  async setHoldingState(campaign: Campaign | null) {
    if (campaign) {
      const end = campaign.endAt.toDate().getTime();
      this.holdingState.next(this.isHolding(end));
    } else {
      this.holdingState.next(false);
    }
  }

  // 開催状況ゲッター
  get holdingState$(): Observable<boolean> {
    return this.holdingState.asObservable();
  }

  // 開催状況判定
  isHolding(end: number): boolean {
    const now = dayjs().toDate().getTime();
    return now < end;
  }

  // シリアル番号の状態をセット
  async setSerialNumState(serialNum: string, campaign: Campaign | null) {
    if (!campaign) {
      this.serialNumState.next({
        serialNum: serialNum,
        status: SerialNumStatus.invalid,
      });
    } else {
      const isValid = this.validateCheckDigit(serialNum, campaign);
      const status: SerialNumStatus = isValid
        ? await this.validateSerialNum(serialNum, environment.campaign_id)
        : SerialNumStatus.invalid;
      this.serialNumState.next({
        serialNum: serialNum,
        status: status,
      });
    }
  }

  // シリアル番号状態ゲッター
  get serialNumState$(): Observable<{
    serialNum: string;
    status: SerialNumStatus;
  }> {
    return this.serialNumState.asObservable();
  }

  // シリアル番号が使用済みかチェックする
  async validateSerialNum(
    serialNum: string,
    campaignId: string
  ): Promise<SerialNumStatus> {
    return await firstValueFrom(
      this.functions.httpsCallable('validateSerialNumber')({
        serialNum: serialNum,
        campaignId: campaignId,
      })
    );
  }

  // チェックディジットの検証
  validateCheckDigit(serialNum: string, campaign: Campaign): boolean {
    let sum = 0;
    const reversed = serialNum.split('').reverse();

    // シリアル番号が13桁以外の場合
    if ([...serialNum].length !== 13) {
      return false;
    }

    // 非数チェック
    let isNumber = false;
    for (const str of reversed) {
      const num = parseInt(str);
      if (isNaN(num)) {
        isNumber = false;
        break;
      } else {
        isNumber = true;
      }
    }
    if (!isNumber) {
      return false;
    }

    // シリアル番号の上6桁(施設コード+キャンペーンコード)が一致するか
    if (!serialNum.startsWith(campaign.code)) {
      return false;
    }
    // チェックディジット検証
    for (let i = 0; i < reversed.length; i++) {
      // 係数、偶数番目の数値に2を掛ける
      const coefficient = (i + 1) % 2 == 0 ? 2 : 1;
      const num = parseInt(reversed[i]) * coefficient;
      if (num >= 10) {
        const tensPlace = num.toString().substring(0, 1);
        const onesPlace = num.toString().substring(1, 2);
        sum += parseInt(tensPlace) + parseInt(onesPlace);
      } else {
        sum += num;
      }
    }
    const remainder = sum % 10;
    if (remainder === 0) {
      return true;
    } else {
      return false;
    }
  }
}
