import * as firestore from 'firebase/firestore';

export interface SerialNumber {
  isUsed: boolean;
  createdAt: firestore.Timestamp | firestore.FieldValue | Date | null;
  updatedAt: firestore.Timestamp | firestore.FieldValue | Date | null;
}

export interface KeyValue {
  [key: string]: number;
}

export const SerialNumStatus = {
  canUse: 10,
  used: 20,
  invalid: 99,
} as const;

export type SerialNumStatus =
  typeof SerialNumStatus[keyof typeof SerialNumStatus];
