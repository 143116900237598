import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Campaign, EntryForm, Present, canSelectPresent, presents } from '@web-entry/models';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, firstValueFrom, map, Observable } from 'rxjs';
import * as dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  docId: string = environment.campaign_id;
  campaign = new BehaviorSubject<Campaign | null>(null);
  private entryForm$: EntryForm | null = null;
  selectedPresent = new BehaviorSubject<Present | null>(null);
  canSelectPresent = canSelectPresent
  presents = presents
  constructor(private db: AngularFirestore) {
    if(!this.canSelectPresent) {
      this.selectedPresent.next(this.presents[0]); 
    }
  }

  async initCampaignData(): Promise<void> {
    console.log('データ取得開始');
    // fireStoreからキャンペーン情報取得
    try {
      const dataObs = await this.db
        .doc<Campaign>(`campaigns/${this.docId}`)
        .snapshotChanges()
        .pipe(
          map((snapshot) => {
            if (snapshot.payload.exists) {
              const payloadData = snapshot.payload.data();
              console.log(payloadData);
              // キャンペーン開始日時チェック現在時刻が開始日時より前の場合はデータを返さない
              const start = payloadData.startAt.toDate().getTime();
              const now = dayjs().toDate().getTime();
              if (now < start) {
                return null;
              } else {
                return payloadData;
              }
            } else {
              return null;
            }
          })
        );
      const data = await firstValueFrom(dataObs);
      this.campaign.next(data);
    } catch (error) {
      console.log(error);
      this.campaign.next(null);
    }
  }

  get campaign$(): Observable<Campaign | null> {
    return this.campaign.asObservable();
  }

  get campaignValues(): Promise<Campaign | null> {
    return (async () => {
      const obs = await this.campaign$;
      return await firstValueFrom(obs);
    })();
  }

  get entryForm(): EntryForm | null {
    return this.entryForm$;
  }
  set entryForm(entryForm: EntryForm | null) {
    this.entryForm$ = entryForm;
  }
}
