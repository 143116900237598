import { Answers } from './entry';

export const QuestionType = {
  radioButton: 0,
  checkbox: 1,
  radioButtonWithOther: 2,
  checkboxWithOther: 3,
  freeText: 4
} as const;

export type QuestionType = typeof QuestionType[keyof typeof QuestionType];

// アンケート設問一覧
export const questionnaires: {
  question: string;
  answers: string[];
  questionType: QuestionType;
  formControlName: keyof Answers;
}[] = [
  {
    question: 'Q1.させぼ五番街にはどなたとご来館されましたか？',
    answers: ['ご友人', 'ご家族', '恋人', '仕事仲間', 'その他'],
    questionType: QuestionType.checkboxWithOther,
    formControlName: 'answer1',
  },
  {
    question: 'Q2.GO!五!WEEKでご参加いただいたコンテンツを教えてください？',
    answers: [
      'GWプレゼントキャンペーン',
      'GWスペシャルグルメ',
      'GWスペシャルセール',
      'GWファミリーフェスタ',
      '五番街の日GW Special 10Days 5%OFF',
      '母の日・父の日川柳コンテスト'
    ],
    questionType: QuestionType.checkbox,
    formControlName: 'answer2',
  },
  {
    question: 'Q3.させぼ五番街で実施して欲しいイベントをご入力ください。',
    answers: [],
    questionType: QuestionType.freeText,
    formControlName: 'answer3',
  },
  {
    question: 'Q4.GO!五!WEEKについてご感想をお聞かせください',
    answers: ['大変良い', '良い', 'やや良くない', '良くない', 'その他'],
    questionType: QuestionType.radioButtonWithOther,
    formControlName: 'answer4',
  },
];
