export const presents: Present[] = [
  {
    asset: '',
    shop: '',
    name: '1等：させぼ五番街ギフトカード10,000円分（5名様）',
    num: 5,
    id: 1,
  },
  {
    asset: '',
    shop: '',
    name: '2等：させぼ五番街ギフトカード5,000円分（10名様）',
    num: 10,
    id: 2,
  },
  {
    asset: '',
    shop: '',
    name: '3等：させぼ五番街ギフトカード3,000円分（20名様）',
    num: 20,
    id: 3,
  },
  {
    asset: '',
    shop: '',
    name: '4等：させぼ五番街ギフトカード2,000円分（30名様）',
    num: 30,
    id: 4,
  },
  {
    asset: '',
    shop: '',
    name: '5等：させぼ五番街ギフトカード1,000円分（135名様）',
    num: 135,
    id: 5,
  },
];

export interface Present {
  asset: string;
  name: string;
  shop: string;
  num: number;
  id: number;
}

export const canSelectPresent = false;
